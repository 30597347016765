#header {
    > .pesquisa {
        position: fixed;
        z-index: 999999999999;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        opacity: 0;
        transition: 0.3s;
        top: 0;
        left: 0;
        &.active {
            opacity: 1;
            pointer-events: all;
        }
        > .form {
            width: 90vw;
            max-width: 1000px;
            form {
                > .inputs {
                    width: 100%;
                    position: relative;

                    label,
                    button {
                        position: absolute;
                    }

                    label {
                        color: white;
                        left: 0;
                        top: -15px;
                    }
                    button {
                        right: 0;
                        top: 0;
                        height: 50px;
                        background: transparent;
                        border: 0;
                    }

                    span {
                        width: 100px;
                        display: block;
                        margin: 15px auto;
                        cursor: pointer;
                        color: white;
                        opacity: 0.3;
                        &:hover {
                            opacity: 1;
                        }
                    }

                    input {
                        width: 100%;
                        height: 50px;
                        border: 0;
                        border-bottom: 1px solid white;
                        background-color: transparent;
                        color: white;
                    }

                    img {
                        width: 24px;
                        height: 25px;
                        filter: brightness(100);
                    }
                }
            }
        }
    }
    .primary {
        background-color: #ffffff;
        .container {
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 50px;
            img.logo {
                width: 140px;
                height: 70px;
            }
            > div {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 30px;
                transition: 0.3s;

                @media (max-width: 991px) {
                    position: fixed;
                    background-color: var(--ion-color-secondary);
                    height: 100vh;
                    width: 300px;
                    flex-direction: column;
                    top: 0;
                    right: -500px;
                    justify-content: center;
                    z-index: 99998;

                    &.active {
                        right: 0;
                    }
                }

                a {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    letter-spacing: -0.025em;
                    color: #4f4f4f;
                    display: flex;
                    gap: 10px;
                    align-items: center;

                    &:last-child {
                        padding: 10px 20px;
                        border: 2px solid var(--ion-color-primary);
                        border-radius: 5px;
                        color: var(--ion-color-primary);

                        @media (max-width: 991px) {
                            color: var(--ion-color-secondary-shade);
                            border: 2px solid var(--ion-color-secondary-shade);
                        }
                    }
                }

                button {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    letter-spacing: -0.025em;
                    color: #4f4f4f;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    border: 0;
                    background-color: transparent;
                    cursor: pointer;
                }
            }

            > button {
                display: none;
                width: 40px;
                height: 35px;
                background-color: var(--ion-color-secondary-shade);
                border: 0;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;
                z-index: 99999;

                @media (max-width: 991px) {
                    display: flex;
                }
            }
        }
    }
}
